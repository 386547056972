import { Fragment } from "preact";

const GoogleAdsense = ({}) =>
  process.env.GoogleAdsense_Enabled ? (
    <Fragment>
      <script
        async
        src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-${process.env.GoogleAdsense_PubID}`}
        crossorigin="anonymous"
      />

      <ins
        class="adsbygoogle"
        style="display:block; text-align:center;"
        data-ad-layout="in-article"
        data-ad-format="fluid"
        data-ad-client={`ca-pub-${process.env.GoogleAdsense_PubID}`}
        data-ad-slot="2879463446"
      />

      <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
    </Fragment>
  ) : null;

export default GoogleAdsense;
