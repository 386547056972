import Article from "./article";
import Center from "../../components/center";

const title = "Inline-block for vertically aligned grids";

const ArticleContent = () => (
  <Article title={title}>
    <Center>
      <h1>{title}</h1>

      <p class="entry">
        If you've tried to vertically align items with CSS, you know it can be
        tricky but possible. Where it appears to get complicated is when trying
        to build a responsive web design (RWD) grid that vertically aligns items
        in each row... and the rows can wrap and split into more rows. Who would
        have guessed the answer was a simple CSS2 feature? Not sure its right
        for your site? Check it out on{" "}
        <a href="https://www.halowaypoint.com/en-us">Halo Waypoint</a> the
        Official Halo website.
      </p>

      <p>
        If you ran into issues in that past, it's probably because your grid was
        designed using CSS floats instead of inline-block.
      </p>

      <figure>
        <iframe
          src="//codepen.io/tysonmatanich/embed/dxlDv?height=268&theme-id=23666&default-tab=result&embed-version=2"
          scrolling="no"
          allowTransparency
          allowFullScreen
          height="268"
          style="width:100%;"
        />
        <figcaption>
          Try resizing your browser, breakpoints are set at 300, 500, and 700
          pixels which move boxes to multiple rows.
        </figcaption>
      </figure>

      <h3>How it works</h3>
      <p>
        <code>Inline-block</code> allows elements to flow like inline elements
        but respect properties, such as width, like block elements. This is
        basic stuff, however things get exciting when{" "}
        <code>vertical-align</code> is set to <code>middle</code>. Suddenly,
        elements on the same horizontal row align themselves vertically to each
        other. Try stepping it up a notch and change some elements to{" "}
        <code>vertical-align:bottom</code> and <code>vertical-align:top</code>
        ... crazy stuff the elements actually do what you tell them to!
      </p>
      <figure>
        <pre>
          <code>
            {`.row div {
  /* The vertical alignment magic */
  display: inline-block;
  vertical-align: middle;
  width: 25%; /* Width controls number of elements per row */
}`}
          </code>
        </pre>
        <figcaption>
          <code>inline-block</code> allows the elements to flow and vertically
          align like inline elements.
        </figcaption>
      </figure>

      <p>
        The only part that feels like a hack is that you must remove all
        whitespace between the elements in the row. However, this makes perfect
        sense since the items are set as <code>inline-block</code> the
        whitespace will cause space between the items. This is the same behavior
        as a link within a paragraph of text; the whitespace around the link is
        rendered as a space just like it would between two words. There are
        several different{" "}
        <a href="https://css-tricks.com/fighting-the-space-between-inline-block-elements/">
          approaches
        </a>{" "}
        you can take to remove these spaces.
      </p>
      <figure>
        <pre>
          <code>
            {`<div class="row">
   <div class="a"></div><!--
--><div class="b"></div><!--
--><div class="c"></div><--
--><div class="d"></div><--
--><div class="e"></div><--
--><div class="f"></div>
</div>`}
          </code>
        </pre>
        <figcaption>
          Html comments are one way used to remove inline whitespace, while
          still having the elements' HTML on their own lines.
        </figcaption>
      </figure>

      <h3>Support for reversing flow</h3>
      <p>
        The order of columns can be easily reversed by changing a row's{" "}
        <code>direction</code> property as follows:
      </p>

      <figure>
        <pre>
          <code>
            {`.row {
  direction: rtl;
}
.row div {
  direction: lrt;
}`}
          </code>
        </pre>
        <figcaption>
          Direction can be changed for the row and changed back for boxes.
        </figcaption>
      </figure>

      <p>
        This results in the elements flowing from right-to-left instead of
        left-to-right.
      </p>

      <figure>
        <iframe
          src="//codepen.io/tysonmatanich/embed/GIkea?height=268&theme-id=23666&default-tab=result&embed-version=2"
          scrolling="no"
          allowTransparency
          allowFullScreen
          height="268"
          style="width:100%;"
        />
        <figcaption>
          Try resizing your browser, breakpoints are set at 300, 500, and 700
          pixels which move boxes to multiple rows.
        </figcaption>
      </figure>

      <h3>Even supports IE 6</h3>
      <p>
        IE6 is off many of our radars, however there are still some sites that
        need to support it and several that need to support IE7.
      </p>

      <figure>
        <pre>
          <code>
            {`div {
  *display: inline;
  *zoom:1;
  *width: 16.6%;
}`}
          </code>
        </pre>
        <figcaption>
          A simple 3-line fix adds support for IE 6 and IE 7.
        </figcaption>
      </figure>

      <h3>Further reading</h3>
      <ul>
        <li>
          <a href="https://css-tricks.com/centering-in-the-unknown/">
            Centering in the Unknown
          </a>{" "}
          by Chris Coyier
        </li>
        <li>
          <a href="https://css-tricks.com/what-is-vertical-align/">
            What is Vertical Align?
          </a>{" "}
          by Chris Coyier
        </li>
        <li>
          <a href="/2013/01/07/viewport-size/">What size is my viewport?</a> by
          Tyson Matanich
        </li>
      </ul>
    </Center>
  </Article>
);

export default ArticleContent;
