import style from "./style.scss";
import namedRoutes from "../../settings/routes";

const Footer = () => (
  <footer class={style.footer}>
    <div>
      <ul class={style.social_links}>
        <li>
          <a title="Twitter" href="https://twitter.com/tysonmatanich">
            <svg
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
              fill-rule="evenodd"
              clip-rule="evenodd"
              stroke-linejoin="round"
              stroke-miterlimit="1.414"
            >
              <path
                d="M16 3.038c-.59.26-1.22.437-1.885.517.677-.407 1.198-1.05 1.443-1.816-.634.375-1.337.648-2.085.795-.598-.638-1.45-1.036-2.396-1.036-1.812 0-3.282 1.468-3.282 3.28 0 .258.03.51.085.75C5.152 5.39 2.733 4.084 1.114 2.1.83 2.583.67 3.147.67 3.75c0 1.14.58 2.143 1.46 2.732-.538-.017-1.045-.165-1.487-.41v.04c0 1.59 1.13 2.918 2.633 3.22-.276.074-.566.114-.865.114-.21 0-.416-.02-.617-.058.418 1.304 1.63 2.253 3.067 2.28-1.124.88-2.54 1.404-4.077 1.404-.265 0-.526-.015-.783-.045 1.453.93 3.178 1.474 5.032 1.474 6.038 0 9.34-5 9.34-9.338 0-.143-.004-.284-.01-.425.64-.463 1.198-1.04 1.638-1.7z"
                fill-rule="nonzero"
              />
            </svg>
          </a>
        </li>
        <li>
          <a title="LinkedIn" href="https://www.linkedin.com/in/tysonmatanich">
            <svg
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
              fill-rule="evenodd"
              clip-rule="evenodd"
              stroke-linejoin="round"
              stroke-miterlimit="1.414"
            >
              <path
                d="M13.632 13.635h-2.37V9.922c0-.886-.018-2.025-1.234-2.025-1.235 0-1.424.964-1.424 1.96v3.778h-2.37V6H8.51V7.04h.03c.318-.6 1.092-1.233 2.247-1.233 2.4 0 2.845 1.58 2.845 3.637v4.188zM3.558 4.955c-.762 0-1.376-.617-1.376-1.377 0-.758.614-1.375 1.376-1.375.76 0 1.376.617 1.376 1.375 0 .76-.617 1.377-1.376 1.377zm1.188 8.68H2.37V6h2.376v7.635zM14.816 0H1.18C.528 0 0 .516 0 1.153v13.694C0 15.484.528 16 1.18 16h13.635c.652 0 1.185-.516 1.185-1.153V1.153C16 .516 15.467 0 14.815 0z"
                fill-rule="nonzero"
              />
            </svg>
          </a>
        </li>
        <li>
          <a title="GitHub" href="https://github.com/tysonmatanich">
            <svg
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
              fill-rule="evenodd"
              clip-rule="evenodd"
              stroke-linejoin="round"
              stroke-miterlimit="1.414"
            >
              <path d="M8 0C3.58 0 0 3.582 0 8c0 3.535 2.292 6.533 5.47 7.59.4.075.547-.172.547-.385 0-.19-.007-.693-.01-1.36-2.226.483-2.695-1.073-2.695-1.073-.364-.924-.89-1.17-.89-1.17-.725-.496.056-.486.056-.486.803.056 1.225.824 1.225.824.714 1.223 1.873.87 2.33.665.072-.517.278-.87.507-1.07-1.777-.2-3.644-.888-3.644-3.953 0-.873.31-1.587.823-2.147-.083-.202-.358-1.015.077-2.117 0 0 .672-.215 2.2.82.638-.178 1.323-.266 2.003-.27.68.004 1.364.092 2.003.27 1.527-1.035 2.198-.82 2.198-.82.437 1.102.163 1.915.08 2.117.513.56.823 1.274.823 2.147 0 3.073-1.87 3.75-3.653 3.947.287.246.543.735.543 1.48 0 1.07-.01 1.933-.01 2.195 0 .215.144.463.55.385C13.71 14.53 16 11.534 16 8c0-4.418-3.582-8-8-8" />
            </svg>
          </a>
        </li>
        <li>
          <a title="CodePen" href="https://codepen.io/tysonmatanich/">
            <svg
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
              fill-rule="evenodd"
              clip-rule="evenodd"
              stroke-linejoin="round"
              stroke-miterlimit="1.414"
            >
              <path
                d="M15.988 5.443c-.004-.02-.007-.04-.012-.058l-.01-.033c-.006-.017-.012-.034-.02-.05-.003-.012-.01-.023-.014-.034l-.023-.045-.02-.032-.03-.04-.024-.03c-.01-.013-.022-.026-.034-.038l-.027-.027-.04-.032-.03-.024-.012-.01L8.38.117c-.23-.155-.53-.155-.76 0L.305 4.99.296 5c-.012.007-.022.015-.032.023-.014.01-.027.02-.04.032l-.027.027-.034.037-.024.03-.03.04c-.006.012-.013.022-.02.033l-.023.045-.015.034c-.007.016-.012.033-.018.05l-.01.032c-.005.02-.01.038-.012.058l-.006.03C.002 5.5 0 5.53 0 5.56v4.875c0 .03.002.06.006.09l.007.03c.003.02.006.04.013.058l.01.033c.006.018.01.035.018.05l.015.033c.006.016.014.03.023.047l.02.03c.008.016.018.03.03.042.007.01.014.02.023.03.01.012.02.025.034.036.01.01.018.02.028.026l.04.033.03.023.01.01 7.31 4.876c.116.078.248.117.382.116.134 0 .266-.04.38-.116l7.314-4.875.01-.01c.012-.007.022-.015.032-.023.014-.01.027-.02.04-.032l.027-.027.034-.037.024-.03.03-.04.02-.032.023-.046.015-.033.018-.052.01-.033c.005-.02.01-.038.013-.058 0-.01.003-.02.004-.03.004-.03.006-.06.006-.09V5.564c0-.03-.002-.06-.006-.09l-.007-.03zM8 9.626L5.568 8 8 6.374 10.432 8 8 9.626zM7.312 5.18l-2.98 1.993-2.406-1.61 5.386-3.59v3.206zM3.095 8l-1.72 1.15v-2.3L3.095 8zm1.237.828l2.98 1.993v3.208l-5.386-3.59 2.406-1.61zm4.355 1.993l2.98-1.993 2.407 1.61-5.387 3.59v-3.206zM12.905 8l1.72-1.15v2.3L12.905 8zm-1.237-.827L8.688 5.18V1.97l5.386 3.59-2.406 1.61z"
                fill-rule="nonzero"
              />
            </svg>
          </a>
        </li>
        <li>
          <a title="Message" href={namedRoutes.about}>
            <svg
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
              fill-rule="evenodd"
              clip-rule="evenodd"
              stroke-linejoin="round"
              stroke-miterlimit="1.414"
            >
              <path
                d="M15.244 7.386c-.023.607-.112 1.204-.266 1.79-.155.587-.387 1.11-.696 1.572-.31.46-.703.833-1.18 1.117-.48.283-1.053.425-1.723.425-.47 0-.885-.102-1.246-.305-.36-.203-.64-.49-.842-.863-.56.756-1.314 1.134-2.258 1.134-.413 0-.788-.092-1.126-.275-.337-.183-.62-.444-.845-.785-.227-.34-.39-.75-.49-1.228-.1-.478-.122-1.012-.064-1.602.084-.72.248-1.376.488-1.962.24-.587.54-1.088.902-1.503.36-.415.774-.735 1.24-.958.468-.223.97-.335 1.508-.335.344 0 .644.026.902.077.258.052.49.122.7.21.21.09.402.19.58.306.177.114.355.232.532.352l-.438 5.04c-.03.242-.024.438.013.59.037.15.097.273.18.364.083.092.18.155.292.19.11.034.224.05.34.05.245 0 .474-.084.686-.252.21-.17.395-.403.55-.7.154-.298.277-.65.37-1.052.09-.404.145-.84.162-1.31.046-.893-.023-1.71-.206-2.448-.184-.738-.486-1.37-.906-1.894-.42-.523-.962-.93-1.624-1.22-.66-.288-1.45-.433-2.366-.433-.87 0-1.65.17-2.34.51-.69.342-1.283.813-1.778 1.414-.495.602-.883 1.31-1.164 2.127-.28.815-.443 1.7-.49 2.65-.045 1.03.038 1.93.25 2.704.212.774.54 1.42.988 1.938.446.518 1.004.906 1.674 1.164.67.257 1.44.386 2.31.386.247 0 .5-.014.756-.043.258-.028.51-.068.76-.12.25-.05.487-.11.713-.176.226-.067.43-.14.614-.22l.317 1.426c-.2.13-.43.244-.687.34-.258.093-.528.172-.812.235-.283.064-.57.11-.863.143-.292.03-.57.047-.833.047-1.162 0-2.193-.166-3.092-.498-.898-.332-1.65-.826-2.254-1.482-.604-.655-1.055-1.47-1.353-2.447-.297-.976-.423-2.11-.378-3.397.052-1.174.268-2.26.65-3.26.38-.998.902-1.86 1.566-2.59C3.636 1.6 4.43 1.032 5.356.62 6.28.206 7.31 0 8.45 0c1.134 0 2.133.18 2.998.537.864.358 1.586.86 2.164 1.51.578.65 1.006 1.428 1.284 2.333.278.905.394 1.907.348 3.006zm-8.588.98c-.063.743-.005 1.306.176 1.687.18.38.47.57.87.57.087 0 .186-.018.298-.055.11-.037.226-.106.343-.206.118-.1.234-.24.348-.42.116-.18.222-.418.32-.71l.342-3.916c-.17-.046-.35-.07-.532-.07-.344 0-.638.07-.885.203-.246.134-.455.333-.627.596-.17.264-.31.59-.416.98-.106.39-.185.835-.236 1.34z"
                fill-rule="nonzero"
              />
            </svg>
          </a>
        </li>
      </ul>
    </div>
  </footer>
);

export default Footer;
