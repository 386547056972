import Article from "./article";
import Center from "../../components/center";

const title = "Don't break the Web";

const ArticleContent = () => (
  <Article title={title}>
    <Center>
      <h1>{title}</h1>

      <p>
        <a href="https://twitter.com/reybango">Rey Bango</a> and I recently
        spoke at <a href="https://www.buildwindows.com/">{"//build/"}</a> about
        Web standards and the new{" "}
        <a href="https://www.microsoft.com/">Microsoft.com</a> homepage. Rey
        kicks it off for the first 30 mins discussing why standards matter, our
        responsibility as developers, and some approaches to building a modern
        yet standards based site. I come on at 31 mins and give an overview of
        how responsive Web design was used on Microsoft's homepage and some
        things to consider while building your own responsive site. The session
        can be viewed online at{" "}
        <a href="https://channel9.msdn.com/Events/Build/2012/3-136">
          Channel 9
        </a>
        .
      </p>

      <p>
        <a
          href="https://channel9.msdn.com/Events/Build/2012/3-136"
          class="button"
        >
          View video
        </a>
      </p>
    </Center>
  </Article>
);

export default ArticleContent;
