import Article from "./article";
import Center from "../../components/center";

const title = "Element Query Polyfill";

const ArticleContent = () => (
  <Article title={title}>
    <Center>
      <h1>Media Queries Are Not The Answer: Element Query Polyfill</h1>

      <p class="entry">
        Responsive Web design has transformed how websites are designed and
        built. It has inspired us to think beyond device classifications and to
        use media queries to adapt a layout to the browser’s viewport size.
        This, however, deviates from the hierarchical structure of CSS and
        characterizes elements relative to the viewport, instead of to their{" "}
        <strong>container</strong>.
      </p>

      <p>
        Extensive use of media queries might be the answer for today, but it is
        not a viable long-term solution. Media queries do not allow for reusable
        modules that adapt based on their <strong>containers’</strong> size...
      </p>

      <div class="button-list">
        <a
          class="button"
          href="https://www.smashingmagazine.com/2013/06/media-queries-are-not-the-answer-element-query-polyfill/"
        >
          Continue reading...
        </a>{" "}
        <a
          class="button secondary"
          href="https://github.com/tysonmatanich/elementQuery"
        >
          View on GitHub
        </a>
      </div>

      <p class="caption">
        Full article is published on{" "}
        <a href="https://www.smashingmagazine.com/2013/06/media-queries-are-not-the-answer-element-query-polyfill/">
          Smashing Magazine
        </a>
        .
      </p>

      <h2>Important Update: CSS Container Queries</h2>

      <p>
        As element queries got closer to implementation, they received a new
        name that better reflected their functionality. Element queries are now{" "}
        <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Container_Queries">
          CSS Container Queries
        </a>
        . In September 2022 browser support landed in Chrome, Edge, and Safari.
        Opera followed suit a few months later, then on Valentine's Day 2023
        Firefox joined the club and showed us all some love.
      </p>

      <p>CSS Container Queries are supported in the following browsers:</p>
      <ul>
        <li>Chrome 106 - Sep 27, 2022</li>
        <li>Edge 106 - Sep 15, 2022</li>
        <li>Safari 16.0 - September 12, 2022</li>
        <li>Firefox 110 - February 14, 2023</li>
        <li>Opera 94 - December 15, 2022</li>
        <li>Chrome for Android 109 - January 10, 2023</li>
        <li>Safari on iOS 16.0 - September 12, 2022</li>
        <li>Opera Mobile 73 - February 2, 2023</li>
        <li>Android Browser 109 - January 10, 2023</li>
      </ul>

      <p class="caption">
        View up to date support on{" "}
        <a href="https://caniuse.com/css-container-queries">Can I Use</a>. The
        list above was updated in February 2023.
      </p>

      <p>
        It's exciting to see how much progress has occurred since I published my
        article almost 10 years ago. Thank you to everyone that helped make this
        possible!
      </p>

      <h3>Further reading</h3>
      <ul>
        <li>
          <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Container_Queries">
            MDN - CSS Container Queries
          </a>
        </li>
        <li>
          <a href="https://caniuse.com/css-container-queries">
            Can I Use - CSS Container Queries
          </a>{" "}
          (up to date browser support)
        </li>
        <li>
          <a href="https://www.w3.org/TR/css-contain-3/">
            CSS Containment Module Level 3
          </a>{" "}
          (Official W3C spec)
        </li>
        <li>
          <a href="/2014/05/01/inline-block-for-vertically-aligned-grids/">
            Inline-block for vertically aligned grids
          </a>
        </li>
      </ul>
    </Center>
  </Article>
);

export default ArticleContent;
