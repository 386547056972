import { useState } from "preact/hooks";
import { Route, Router, getCurrentUrl } from "preact-router";

import Header from "./header";
import Footer from "./footer";

// Code-splitting is automated for routes
import Home from "../routes/home";
import About from "../routes/about";
import Projects from "../routes/projects";
import Article8 from "../routes/articles/inline-block-for-vertically-aligned-grids";
import Article7 from "../routes/articles/rtl-css-with-sass";
import Article6 from "../routes/articles/element-query-polyfill";
import Article5 from "../routes/articles/em-values-javascript";
import Article4 from "../routes/articles/viewport-size";
import Article3 from "../routes/articles/picture-polyfill";
import Article2 from "../routes/articles/dont-break-the-web";
import Article1 from "../routes/articles/the-blogging-begins";
import NotFound from "../routes/not-found";

import AdsenseTest from "../routes/articles/adsense-test";
//import TodoRemove from '../routes/articles/_test';

const App = () => {
  let [currentUrl, setCurrentUrl] = useState(getCurrentUrl());

  const handleRoute = async (e) => {
    setCurrentUrl(e.url);

    if (typeof window !== "undefined") {
      // Scroll to top of page https://github.com/preactjs/preact-router/issues/319
      window.scrollTo({ top: 0 });
    }
  };

  return (
    <div id="app">
      <Header />
      <main>
        <div class="wrapper">
          <Router url={currentUrl} onChange={handleRoute}>
            <Route component={Home} path="/" />
            <Route component={About} path="/about" />
            <Route component={Projects} path="/projects" />
            {/* <Route component={TodoRemove} path="/todo" /> */}
            <Route component={AdsenseTest} path="/test-823457809/adsense" />
            <Route
              component={Article8}
              path="/2014/05/01/inline-block-for-vertically-aligned-grids"
            />
            <Route component={Article7} path="/2013/09/06/rtl-css-with-sass" />
            <Route
              component={Article6}
              path="/2013/06/25/element-query-polyfill"
            />
            <Route
              component={Article5}
              path="/2013/06/24/em-values-javascript"
            />
            <Route component={Article4} path="/2013/01/07/viewport-size" />
            <Route component={Article3} path="/2012/11/06/picture-polyfill" />
            <Route component={Article2} path="/2012/11/01/dont-break-the-web" />
            <Route
              component={Article1}
              path="/2012/10/29/the-blogging-begins"
            />
            <Route component={NotFound} default />
          </Router>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default App;
