import Article from './article';
import Center from '../../components/center';

const title = 'What size is my viewport?';

const ArticleContent = () => (
	<Article title={title}>
		<Center>
			<h1>{title}</h1>

			<h3>Using JavaScript to get the actual viewport width</h3>

			<p>
				Responsive Web design is making a positive and lasting impact to the web
				as we know it. Along with most change comes new scenarios. I think it's
				safe to say that supporting a flexible viewport is at the top of the
				list. While CSS if often enough, sometimes JavaScript is required.
				Currently, there are several outdated and incorrect ways to get the
				viewport size using JavaScript. Many people use the{' '}
				<code>innerWidth</code> of the browser window, while others use the{' '}
				<code>clientWidth</code> of the <code>documentElement</code>. Others use
				jQuery's <code>$(window).width()</code>, which ends up just using{' '}
				<code>documentElement.clientWidth</code>. Unfortunately, these
				approaches are flawed if what you are wanting is the actual size used in
				CSS media queries. To reduce confusion, I will refer to this as "CSS
				viewport".
			</p>

			<h3>Browser differences</h3>

			<p>
				Cross-browser compatibility still remains an issue with many features,
				CSS viewport size is no exception. For example, WebKit browsers change
				the size of their CSS viewport when scroll bars are visible, while most
				other browsers do not. Since <code>window.innerWidth</code> remains
				constant regardless of the scroll bar state, it's not a good option for
				use with Chrome or Safari. Additionally, Internet Explorer 6, 7, and 8
				do not support <code>window.innerWidth</code>. On the other hand,{' '}
				<code>document.documentElement.clientWidth</code> can change based on
				the scroll bar state and therefore is not a good option for Internet
				Explorer, Firefox, or Opera.
			</p>

			<h3>window.innerWidth vs. documentElement.clientWidth</h3>

			<p>
				If you are using <code>window.innerWidth</code> to get the size the
				viewport irrespective to scroll bar state, then great. Or, if you are
				using <code>documentElement.clientWidth</code> to get what the current
				usable viewport is, then awesome. However, if you are using one or the
				other thinking it will give you the actual CSS, then it's probably time
				to start updating your sites.
			</p>

			<p>
				The following table compares the potential values of{' '}
				<code>innerWidth</code> and <code>clientWidth</code> to the actual CSS
				viewport width.
			</p>
			<figure>
				<div class="table-wrap">
					<table>
						<thead>
							<tr>
								<th>Browser</th>
								<th>.innerWidth</th>
								<th>.clientWidth</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th>IE</th>
								<td
									class="cell-correct"
									title="Value is equal to the CSS viewport"
								>
									==
								</td>
								<td
									class="cell-wrong"
									title="Value is less than or equal to the CSS viewport"
								>
									&lt;=
								</td>
							</tr>
							<tr>
								<th>Firefox</th>
								<td
									class="cell-correct"
									title="Value is equal to the CSS viewport"
								>
									==
								</td>
								<td
									class="cell-wrong"
									title="Value is less than or equal to the CSS viewport"
								>
									&lt;=
								</td>
							</tr>
							<tr>
								<th>Opera</th>
								<td
									class="cell-correct"
									title="Value is equal to the CSS viewport"
								>
									==
								</td>
								<td
									class="cell-wrong"
									title="Value is less than or equal to the CSS viewport"
								>
									&lt;=
								</td>
							</tr>
							<tr>
								<th>Chrome</th>
								<td
									class="cell-wrong"
									title="Value is greater than or equal to the CSS viewport"
								>
									&gt;=
								</td>
								<td
									class="cell-correct"
									title="Value is equal to the CSS viewport"
								>
									==
								</td>
							</tr>
							<tr>
								<th>Safari</th>
								<td
									class="cell-wrong"
									title="Value is greater than or equal to the CSS viewport"
								>
									&gt;=
								</td>
								<td
									class="cell-correct"
									title="Value is equal to the CSS viewport"
								>
									==
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<figcaption>
					Table only represents versions of browsers that support CSS media
					queries.
				</figcaption>
			</figure>

			<p>
				The following table shows a more detailed comparison on how browsers
				treat the CSS viewport when scroll bars are visible.
			</p>
			<figure>
				<div class="table-wrap">
					<table>
						<thead>
							<tr>
								<th>Browser</th>
								<th>@media</th>
								<th>My script</th>
								<th>.innerWidth</th>
								<th>.clientWidth</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th>IE 9</th>
								<td>500</td>
								<td>500</td>
								<td class="cell-correct">500</td>
								<td class="cell-wrong">483</td>
							</tr>
							<tr>
								<th>IE 6, 7, 8</th>
								<td class="cell-na">NA</td>
								<td>484</td>
								<td class="cell-wrong">undefined</td>
								<td>484</td>
							</tr>
							<tr>
								<th>FF 17 Win</th>
								<td>500</td>
								<td>500</td>
								<td class="cell-correct">500</td>
								<td class="cell-wrong">483</td>
							</tr>
							<tr>
								<th>FF 17 Mac</th>
								<td>500</td>
								<td>500</td>
								<td class="cell-correct">500</td>
								<td class="cell-wrong">485</td>
							</tr>
							<tr>
								<th>Chrome 24 Win</th>
								<td>483</td>
								<td>483</td>
								<td class="cell-wrong">500</td>
								<td class="cell-correct">483</td>
							</tr>
							<tr>
								<th>Safari 5 Win</th>
								<td>483</td>
								<td>483</td>
								<td class="cell-wrong">500</td>
								<td class="cell-correct">483</td>
							</tr>
							<tr>
								<th>Opera 12 Win</th>
								<td>500</td>
								<td>500</td>
								<td class="cell-correct">500</td>
								<td class="cell-wrong">483</td>
							</tr>
							<tr>
								<th>Opera 12 Mac</th>
								<td>500</td>
								<td>500</td>
								<td class="cell-correct">500</td>
								<td class="cell-wrong">485</td>
							</tr>
						</tbody>
					</table>
				</div>
				<figcaption>
					Some browsers were omitted from the list that no longer use scroll
					bars that consume layout space.
				</figcaption>
			</figure>

			<h3>Is there a good solution?</h3>

			<p>
				A quick web search will bring back lots of misinformation on obtaining
				the CSS viewport size. After looking at the actual data, the quick and
				dirty solution to the problem is to use user agent detection to toggle
				between innerWidth and clientWidth, but as we all know UA detection
				should only be used as a last resort (unless you are actually targeting
				the UA). I analyzed a lot of different JavaScript objects to find a way
				to calculate the CSS viewport size cross browser without UA sniffing,
				but with no luck. That however did not cause me to give up; if it can be
				done with CSS, then it should be able to be done with JavaScript. This
				concept led me to my current solution.
			</p>

			<h3>Solution</h3>

			<p>
				I found that if scroll bars are not visible, then{' '}
				<code>window.innerWidth</code> and{' '}
				<code>documentElement.clientWidth</code> will both return the correct
				value. However, when scroll bars are visible some additional detection
				is required. The current version of the script injects a CSS media query
				to test if <code>documentElement.clientWidth</code> is equal to the
				current CSS viewport size. If they are equal, it uses the value, however
				if they are not equal, it falls back to use{' '}
				<code>window.innerWidth</code>. There are currently no known unsupported
				browsers and should be ready for production use. Check out the{' '}
				<a href="https://github.com/tysonmatanich/viewportSize">
					project homepage
				</a>{' '}
				to download the uncompressed or minified versions of the script. Enjoy!
			</p>

			<p>
				<a class="button" href="https://github.com/tysonmatanich/viewportSize">
					Get the Script
				</a>
			</p>

			<h4>Related</h4>
			<ul>
				<li>
					<p>
						Do you know what your viewport width is?{' '}
						<a native href="/test/viewport-width/">
							Test your knowledge.
						</a>
					</p>
				</li>
				<li>
					<p>
						Also, checkout my{' '}
						<a href="https://tysonmatanich.github.io/viewportTester/">
							ViewportTester
						</a>{' '}
						bookmarklet that makes viewing your viewport size easy.
					</p>
				</li>
				<li>
					<p>
						<a native href="/examples/pixel-density-tests/">
							Pixel density tests
						</a>
					</p>
				</li>
			</ul>

			<p>
				And yes, I'm aware that I posted a similar script in an{' '}
				<a href="/2012/11/06/picture-polyfill/">
					earlier post
				</a>{' '}
				but thought it deserved a bit more of an explanation.
			</p>
		</Center>
	</Article>
);

export default ArticleContent;
