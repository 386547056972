import Link from "../link";
import style from "./style.scss";
import namedRoutes from "../../settings/routes";

const Header = () => (
  <header class={style.header}>
    <div class={style.navigation}>
      <h2 class={style.brand}>
        <a href={namedRoutes.home}>Tyson Matanich</a>
      </h2>
      <div class={style.spacer} />
      <nav>
        <ul>
          <li>
            <Link activeClassName={style.current} href={namedRoutes.about}>
              About
            </Link>
          </li>
          <li>
            <Link activeClassName={style.current} href={namedRoutes.home}>
              Blog
            </Link>
          </li>
          <li>
            <Link activeClassName={style.current} href={namedRoutes.projects}>
              Projects
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  </header>
);

export default Header;
