import { useEffect } from "preact/hooks";

const Head = ({ title }) => {
  useEffect(() => {
    if (typeof document !== "undefined") {
      document.title = title;
      if (typeof gtag !== "undefined") {
        // Send GA4 (Google Analytics) page view
        gtag("event", "page_view");
      }
    }
  }, [title]);
  return null;
};

export default Head;
