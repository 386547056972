import Article from './article';
import Center from '../../components/center';

const title = 'Em values in JavaScript';

const ArticleContent = () => (
	<Article title={title}>
		<Center>
			<h1>{title}</h1>

			<p class="entry">
				If you don’t know what{' '}
				<a href="https://www.w3.org/TR/css3-values/#em-unit">ems</a> are or{' '}
				<a href="https://css-tricks.com/why-ems/">why</a> you would use them, now
				is the time. Em units are extremely useful and are an essential
				component of responsive Web design (RWD). Unfortunately, browsers have
				failed to provide support for ems in JavaScript.
			</p>

			<p>
				I needed to reliably convert the pixel width value of an element to ems.
				Blindly dividing by a hard-coded value of 16 wasn’t an option. Doing so
				would have resulted in errors when elements had varying font sizes or
				when a user changed the base font size.
			</p>

			<h3>GetEmPixels</h3>

			<p>
				<a href="https://github.com/tysonmatanich/getEmPixels">getEmPixels()</a>{' '}
				allows you to accurately obtain an element's em value in pixels. This
				makes dealing with the cascading inheritance of ems simple. It can also
				be used to find the root em (
				<a href="https://www.w3.org/TR/css3-values/#rem-unit">rem</a>) value of a
				page.
			</p>

			<h4>Interactive Example</h4>
			<p>
				Try out the{' '}
				<a native href="/examples/get-em-pixels/">
					interactive example
				</a>{' '}
				to view it in action and see how em values cascade in CSS.
			</p>

			<figure>
				<a
					style="display:block;background-color:#1d1f20!important;"
					native
					href="/examples/get-em-pixels/"
					class="img-link"
				>
					<img src="/images/getEmPixel.png" alt="getEmPixel example" />
				</a>
			</figure>

			<h4>Basic Examples</h4>

			<p>
				The following code shows how to obtain the em value of the{' '}
				<code>#footer</code> element.
			</p>

			<figure>
				<pre>
					<code class="block">
						{`var footer = document.getElementById('footer');
var footerEm = getEmPixels(footer);`}
					</code>
				</pre>
			</figure>

			<p>
				When no element is passed, <code>getEmPixels</code> returns the rem
				value of the <code>documentElement</code>.
			</p>

			<figure>
				<pre>
					<code class="block">var rem = getEmPixels();</code>
				</pre>
			</figure>

			<p>
				You can then use these values to calculate the width of an element in
				ems or rems.
			</p>

			<figure>
				<pre>
					<code class="block">
						{`var footerEmWidth = footer.clientWidth / footerEm;
var footerRemWidth = footer.clientWidth / rem;`}
					</code>
				</pre>
				<figcaption>
					View{' '}
					<a href="https://codepen.io/tysonmatanich/pen/Lpsqd">full example</a>{' '}
					on CodePen
				</figcaption>
			</figure>

			<h4>Size and delivery</h4>
			<p>
				GetEmPixels weighs in at a mere 481 bytes (0.47 KB). Feel free to
				contribute or fork the code on{' '}
				<a href="https://github.com/tysonmatanich/getEmPixels">GitHub</a>.
			</p>

			<p>
				<a href="https://github.com/tysonmatanich/getEmPixels" class="button">
					Get the Script
				</a>
			</p>

			<h4>Related</h4>
			<ul>
				<li>
					Learn about{' '}
					<a href="https://codepen.io/tysonmatanich/pen/wetLC">font-size bugs</a>{' '}
					in WebKit an Opera
				</li>
			</ul>
		</Center>
	</Article>
);

export default ArticleContent;
