import Article from "./article";
import Center from "../../components/center";

const title = "RTL CSS with Sass";

const ArticleContent = () => (
  <Article title={title}>
    <Center>
      <h1>{title}</h1>

      <p class="entry">
        Supporting both right-to-left (RTL), often bi-directional (BiDi), and
        left-to-right (LTR) renderings of Web pages can be complicated. However,
        including a CSS preprocessor (such as{" "}
        <a href="https://sass-lang.com/">Sass</a> or{" "}
        <a href="https://lesscss.org/">LESS</a>) to your workflow can simplify
        things a lot.
      </p>
      <p>
        I have worked on three major versions of Microsoft's homepage and as you
        would expect, it supports quite a few locales (
        <a href="https://www.microsoft.com/en-us/locale.aspx">
          approximately 100
        </a>
        ). As a result, it needs to provide full support for RTL languages.
      </p>

      <figure>
        <a href="https://www.microsoft.com/en-us/" class="img-link">
          <img src="/images/homepage-ltr-rtl.jpg" alt="Microsoft homepage" />
        </a>
        <figcaption>
          Microsoft's homepage -{" "}
          <a href="https://www.microsoft.com/en-us/">
            English - United States (en-us)
          </a>{" "}
          vs.{" "}
          <a href="https://www.microsoft.com/he-il/">עברית - ישראל (he-il)</a>
        </figcaption>
      </figure>

      <h3>Mirroring the layout</h3>
      <p>
        Converting a LTR layout to RTL is fairly straight forward. In most
        cases, all you have to remember is "left is right and right is left."
        For example, if an element is normally floated to the left, float it to
        the right instead. This logic continues with positioning, margin,
        padding, text alignment, etc. The result is a layout mirroring the
        original.
      </p>

      <p>
        There can be a few exceptions (such as positioning of image sprites) so
        a blind find-and-replace could cause unexpected issues. Additionally,
        conscious decisions not to mirror are sometimes made. For example, in
        the above image, the tile (in this case featuring Office 365) is almost
        always positioned on the left hand side of the slideshow which allows
        the background images to be reused across locales, saving on production
        costs.
      </p>

      <h4>Common approaches</h4>
      <p>
        Some common approaches are manually creating/maintaining two versions of
        the style sheets (extra effort) or by creating an override style sheet
        (extra effort and page weight) for RTL (
        <a href="https://rtl-css.net/tutorial/3-different-ways-rtl-your-css">
          read more
        </a>
        ). But why go through the extra effort if you don't have to?
      </p>

      <figure>
        <pre>
          <code>
            {`div {
  `}
            float: <mark>left</mark>;
            {`
  `}
            text-align: <mark>left</mark>;
            {`
  `}
            margin: 0 <mark>1em</mark> 0 <mark>2em</mark>;
            {`
  `}
            padding-
            <mark>right</mark>: 1em;
            {`
}`}
          </code>
        </pre>
        <figcaption>Example CSS for LTR.</figcaption>
      </figure>

      <figure>
        <pre>
          <code>
            {`div {
  `}
            float: <mark>right</mark>;{" "}
            {`
  `}
            text-align: <mark>right</mark>;
            {`
  `}
            margin: 0 <mark>2em</mark> 0 <mark>1em</mark>;
            {`
  `}
            padding-
            <mark>left</mark>: 1em;
            {`
}`}
          </code>
        </pre>
        <figcaption>Example CSS updated for RTL.</figcaption>
      </figure>

      <h3>Sass helpers</h3>
      <p>
        CSS preprocessors, like Sass, are useful for many things. I have used
        other methods, such as custom ASP.NET pages, to render out CSS based on
        the locale's text direction, however Sass makes creating this capability
        straight forward and gives you many other benefits. Using my few simple
        Sass variables, functions, and mixins described below, should help you
        simplify your RTL CSS workflow. Let me know how it goes!
      </p>

      <figure>
        <pre>
          <code>
            {`div {
  `}
            float: <mark>$left</mark>;
            {`
  `}
            text-align: <mark>$left</mark>;
            {`
  `}
            margin: <mark>side-values(0 2em 0 1em)</mark>;
            {`
  `}
            padding-<mark>{`#{$right}`}</mark>: 1em;
            {`
}`}
          </code>
        </pre>
        <figcaption>Example CSS updated to use Sass helpers.</figcaption>
      </figure>

      <h4>List of features</h4>
      <p>
        After importing my{" "}
        <a href="https://github.com/tysonmatanich/directional-scss">
          directional.scss
        </a>{" "}
        file, all you have to configure is the <code>$dir</code> variable
        (setting it to <code>rtl</code> or <code>ltr</code>) and then make use
        of the other variables, functions, and mixins.
      </p>

      <h5>Variables</h5>
      <ul>
        <li>
          <strong>
            <code>$dir</code>
          </strong>
          <br />
          <pre>
            <code>
              <mark>$dir: rtl;</mark>
              {`
.icon.icon-star {
  `}
              background-image: url(sprite-<mark>{`#{$dir}`}</mark>.png);
              {`
}`}
            </code>
          </pre>
        </li>
        <li>
          <strong>
            <code>$left</code>
          </strong>
          <br />
          <pre>
            <code>
              text-align: <mark>$left</mark>;
            </code>
          </pre>
        </li>
        <li>
          <strong>
            <code>$right</code>
          </strong>
          <br />
          <pre>
            <code>
              padding-<mark>{`#{$right}`}</mark>: 1em;
            </code>
          </pre>
        </li>
      </ul>

      <h5>Functions</h5>
      <ul>
        <li>
          <strong>
            <code>if-ltr($if, $else:null)</code>
          </strong>
          <br />
          <pre>
            <code>
              margin: 0 <mark>if-ltr(!important)</mark>;
              {`
`}
              content: <mark>if-ltr('left-to-right', 'right-to-left')</mark>;
            </code>
          </pre>
        </li>
        <li>
          <strong>
            <code>if-rtl($if, $else:null)</code>
          </strong>
          <br />
          <pre>
            <code>
              background-image: url(sprite<mark>{`#{if-rtl('-rtl')}`}</mark>
              .png);
              {`
`}
              content: <mark>if-rtl('right-to-left', 'left-to-right')</mark>;
            </code>
          </pre>
        </li>
        <li>
          <strong>
            <code>side-values($values)</code>
          </strong>
          <br />
          <pre>
            <code>
              margin: <mark>side-values(0 2em 0 1em)</mark>;
            </code>
          </pre>
        </li>
        <li>
          <strong>
            <code>corner-values($values)</code>
          </strong>
          <br />
          <pre>
            <code>
              border-radius: <mark>corner-values(1em 2em 3em 4em)</mark>;
            </code>
          </pre>
          <pre>
            <code>
              border-radius: <mark>corner-values(1em 2em 3em)</mark>;
            </code>
          </pre>
          <pre>
            <code>
              border-radius: <mark>corner-values(1em 2em)</mark>;
            </code>
          </pre>
        </li>
      </ul>

      <h5>Mixins</h5>
      <ul>
        <li>
          <strong>
            <code>if-ltr {}</code>
          </strong>
          <br />
          <pre>
            <code>
              <mark>{`@include if-ltr {`}</mark>
              {`
  p {
    // some css...
  }
`}
              <mark>{"}"}</mark>
            </code>
          </pre>
        </li>
        <li>
          <strong>
            <code>{`if-rtl {}`}</code>
          </strong>
          <br />
          <pre>
            <code>
              <mark>{`@include if-rtl {`}</mark>
              {`
  // some css...
`}
              <mark>{"}"}</mark>
            </code>
          </pre>
        </li>
      </ul>

      <p>
        <a
          href="https://github.com/tysonmatanich/directional-scss"
          class="button"
        >
          View on GitHub
        </a>
      </p>

      <h3>Further reading</h3>
      <ul>
        <li>
          <a href="/2014/05/01/inline-block-for-vertically-aligned-grids/">
            Inline-block for vertically aligned grids
          </a>{" "}
          by Tyson Matanich
        </li>
      </ul>
    </Center>
  </Article>
);

export default ArticleContent;
