import Article from "./article";
import Center from "../../components/center";
import GoogleAdsense from "../../components/google-adsense";

const title = "Adsense Test";

const ArticleContent = () => (
  <Article title={title}>
    <Center>
      <h1>{title}</h1>

      <p class="entry">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur elit
        nulla, congue id viverra non, posuere eu ex. Suspendisse auctor lobortis
        mauris at rutrum. Fusce molestie, ligula id viverra imperdiet, mi enim
        tempor diam, eget lacinia ex orci non urna. In sagittis ex lobortis
        metus tristique, vel molestie erat vehicula. Aliquam facilisis viverra
        sapien. Duis rhoncus sodales ante quis aliquam. Ut mollis velit at
        ultricies malesuada. Class aptent taciti sociosqu ad litora torquent per
        conubia nostra, per inceptos himenaeos.
      </p>

      <p>
        Sed sed purus sit amet velit posuere elementum ut imperdiet purus.
        Suspendisse non tortor et purus mattis venenatis.
      </p>

      <GoogleAdsense />

      <h3>Lorem Ipsum Dolor Sit</h3>
      <p>
        Ullamcorper dolor ut ligula pulvinar, id mollis quam fermentum. Donec ex
        sapien, ultrices finibus semper ut, ultrices vitae lacus. Cras porttitor
        nulla quis condimentum condimentum. Nunc scelerisque mauris ac mauris
        convallis malesuada. Mauris tempus tincidunt bibendum. Donec fermentum
        tristique enim et congue. Nunc sagittis id massa eget tempus. Vivamus id
        nunc justo. Aenean quis pharetra lectus. Nam condimentum eros eu diam
        elementum, sed gravida justo malesuada.
      </p>

      <h3>Suspendisse Dui Odio</h3>
      <p>
        Nulla porta mi vehicula, cursus urna eu, volutpat sapien. Quisque sit
        amet quam in orci pharetra venenatis vitae quis risus. Ut ultricies
        massa id sagittis dignissim. Nam tristique ex sem. Aliquam odio odio,
        vehicula ac nibh at, ultricies gravida eros. Curabitur ultricies ex et
        urna bibendum semper. Suspendisse potenti. Quisque auctor et arcu vitae
        porttitor. Integer non ultrices enim. In tortor magna, eleifend sit amet
        turpis vitae, efficitur sodales orci. Curabitur porttitor porta feugiat.
        Suspendisse dui odio, dignissim fringilla turpis et, elementum
        vestibulum orci.
      </p>

      <p>
        Nunc lorem dui, dictum vel blandit ac, pharetra ac turpis. Proin eu ante
        ut lectus rutrum dapibus sit amet sit amet massa. Pellentesque
        vestibulum risus ligula, volutpat placerat tellus tincidunt a. Aenean
        vitae dapibus nisl. Vivamus dignissim nunc turpis, vel auctor tellus
        fermentum et.
      </p>
    </Center>
  </Article>
);

export default ArticleContent;
