import { Match } from "preact-router/match";
import { mergePropClasses } from "../helpers";

// Modified from: https://github.com/developit/preact-router/blob/13f185a0c1881bb118982c0782b8cea7604fc937/src/match.js
const Link = ({ activeClassName, ...props }) => (
  <Match path={props.href}>
    {({ matches }) => (
      <a
        {...props}
        class={mergePropClasses(props, matches && activeClassName)}
      />
    )}
  </Match>
);

export default Link;
