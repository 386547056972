import Article from './article';
import Center from '../../components/center';

const title = 'The blogging begins';

const ArticleContent = () => (
	<Article title={title}>
		<Center>
			<h1>{title}</h1>
			<p>
				I will soon begin blogging occasionally. Many of the topics will be
				focused on the work I do on{' '}
				<a href="https://www.microsoft.com">Microsoft.com</a> with its homepage
				and underlying CMS.
			</p>
		</Center>
	</Article>
);

export default ArticleContent;
