import Editorial from "../../../components/editorial";
import Head from "../../../components/head";

const Article = ({ title, children }) => (
  <div>
    <Head title={title} />
    <Editorial>{children}</Editorial>
  </div>
);

export default Article;
